<!--  -->
<template>
  <div class="form-container">
    <div class="top">
      <img
        class="logo"
        :src="require('@/assets/images/logo.png')"
        alt=""
        srcset=""
      />
      <p class="title">航都院OA</p>
    </div>
    <div class="box">
      <!-- 密码登录 -->
      <div
        :class="['password-content', loginFormType == 0 ? 'active' : '']"
        v-if="loginFormType == 0"
      >
        <van-form :model="loginForm" :rules="loginRules" class="login-form">
          <input
            class="login-input"
            v-model.trim="loginForm.staffCode"
            placeholder="请输入工号/手机号/邮箱"
            :maxlength="32"
          />
          <div class="pass-box">
            <input
              class="login-input-pass"
              :type="flag ? 'text' : 'password'"
              v-model.trim="loginForm.password"
              placeholder="输入密码"
            />
            <div class="eye-box" @click="showPass">
              <img :src="eye" alt="" class="eye" v-if="flag" />
              <img :src="closeEye" alt="" class="eye" v-else />
            </div>
          </div>

          <div class="check-code">
            <input
              class="code-input"
              v-model.trim="loginForm.imageCode"
              placeholder="请输入验证码"
            />
            <div class="code">
              <img class="verication-img" :src="imgUrl" alt="" srcset="" />
              <div type="text" @click="getImg">点击刷新验证码</div>
            </div>
          </div>

          <van-button
            type="primary"
            class="login-btn"
            :disabled="isLoading"
            @click="handleLogin"
            >登录</van-button
          >
          <div class="go-forget-pass">
            <div type="text" @click="goForgetPass">忘记密码</div>
          </div>
        </van-form>
        <p class="switch-name" @click="switchPhone">
          <span class="switch-text">验证码登录</span>
        </p>
        <p class="wechat-login" @click="switchWechat" v-if="isWeixin">
          <span class="switch-text">微信登录</span>
        </p>
      </div>
      <!-- 短信登录 -->
      <div
        :class="['password-content', loginFormType == 1 ? 'active' : '']"
        v-if="loginFormType == 1"
      >
        <van-form :model="phoneForm" :rules="phoneRules" class="login-form">
          <input
            class="login-input"
            v-model.trim="phoneForm.phone"
            placeholder="输入手机号"
            name="phone"
            type="text"
          />
          <div class="check-msg">
            <input
              class="msg-input"
              v-model.trim="phoneForm.code"
              placeholder="输入验证码"
              name="code"
            />
            <template>
              <div class="count" v-if="isShow" type="text" @click="sendMsg">
                获取验证码
              </div>
              <div class="count" v-if="!isShow">
                <span>{{ count }}</span> 秒
              </div>
            </template>
          </div>
          <van-button
            type="primary"
            class="login-btn"
            :disabled="isLoading"
            @click="handleLogin"
            >登录</van-button
          >
        </van-form>
        <p class="switch-name" @click="switchPass">
          <span class="switch-text">密码登录</span>
        </p>
        <p class="wechat-login" @click="switchWechat" v-if="isWeixin">
          <span class="switch-text">微信登录</span>
        </p>
        <p class="switch-name" @click="register" v-if="!isWeixin">
          <span class="switch-text">注册</span>
        </p>
      </div>


      <!-- 微信登录 -->
      <div
        :class="['wechat-content', loginFormType == 3 ? 'active' : '']"
        v-if="loginFormType == 3"
      >
        <van-button @click="goWechatLogin" type="primary" class="login-btn">
          <div class="wechat-button">
            <img
              class="wechat-logo"
              src="@/assets/images/login/weChatLogo.png"
            />
            <span>微信授权登录</span>
          </div>
        </van-button>
        <div class="split">
          <p class="split-text">其他方式登录</p>
        </div>
        <div class="other-login">
          <div class="item" @click="switchPhone">
            <img
              src="@/assets/images/login/phoneLogin.png"
              class="other-logo"
            />
            <p class="text">手机号登录</p>
          </div>
          <div class="item" @click="switchPass">
            <img src="@/assets/images/login/passLogin.png" class="other-logo" />
            <p class="text">账号密码登录</p>
          </div>
        </div>
      </div>
      <div class="agreement">
        <van-checkbox v-model="checked">我已阅读并同意 </van-checkbox>
        <span class="link" @click="goAgreementZC">《用户服务协议》</span
        ><span class="link" @click="goAgreementYS">《隐私权政策》</span>
      </div>
      <!-- <div class="tips">
        <div>
          <van-icon name="info-o" />
        </div>
        <div>
          <p>本软件仅供航都院公司内部办公使用；</p>
          <p>不提供开放注册；</p>
          <p>航都院公司员工请联系公司管理员;</p>
          <p>由管理员线下分配账号</p>
        </div>
      </div> -->
    </div>

    <van-dialog v-model="dialogZC" title="用户服务协议">
      <AgreementZC />
    </van-dialog>
    <van-dialog v-model="dialogYS" title="隐私权政策">
      <AgreementYS />
    </van-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Schema from "async-validator";
import eye from "@/assets/images/login/eye.png";
import closeEye from "@/assets/images/login/closeEye.png";

import { isWeixin } from "@/utils/isWeixin.js";
import AgreementYS from "@/components/AgreementYS";
import AgreementZC from "@/components/AgreementZC";

//传入登录类型loginType，1：工号staffCode，2：手机号phone，3：邮箱email
export default {
  data() {
    const validateCommonCode = (rule, value, callback) => {
      const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      const emailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value === "") {
        return callback(new Error("请输入工号/手机号/邮箱"));
      } else if (phoneReg.test(value)) {
        this.loginForm.loginType = "2";
        return callback();
      } else if (emailReg.test(value)) {
        this.loginForm.loginType = "3";
        return callback();
      } else {
        this.loginForm.loginType = "1";
        return callback();
      }
    };

    return {
      checked: false,
      dialogZC: false,
      dialogYS: false,
      count: 0,
      isShow: true, //通过 v-show 控制显示'获取按钮'还是'倒计时'
      flag: false,
      imgBg: require("@/assets/images/bg.gif"),
      logo: require("@/assets/images/left_logo.png"),
      eye,
      closeEye,
      dialogFormVisible: false,
      isLoading: false,
      isWeixin,
      loginFormType: isWeixin ? 3 : 1,

      loginForm: {
        email: null,
        imageCode: "",
        imageKey: "",
        loginType: "1",
        password: "",
        phone: null,
        staffCode: "",
      },
      loginRules: {
        staffCode: [
          {
            required: true,
            trigger: "blur",
            message: "请输入工号/手机号/邮箱",
          },
          {
            trigger: "blur",
            message: "请输入账号",
            validator: validateCommonCode,
          },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
      },
      phoneForm: {
        phone: "",
        code: "",
      },
      phoneRules: {
        phone: [{ required: true, trigger: "blur", message: "请输入手机号" }],
        code: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      },
      codeText: "",
      loginFormTypePre: 0,

      qrCodeStatus: 0,
      pageUrl: "",
    };
  },
  watch: {
    loginFormType() {},
  },
  components: { AgreementYS, AgreementZC },

  created() {
    const { id } = this.$route.query;
    this.pageUrl = id;
  },
  computed: {
    imgUrl() {
      return `${process.env.VUE_APP_BASE_URL}/account/imagecode?key=${this.loginForm.imageKey}`;
    },
  },

  mounted() {
    this.getImg();
  },

  methods: {
    ...mapActions("login", [
      "loginAction",
      "phoneLoginAction",
      "queryUserInfoAction",
      "imageCodeAction",
      "sendCodeAction",
      "h5LoginAction",
    ]),

    getImg() {
      this.loginForm.imageKey = new Date().getTime();
    },
    sendMsg() {
      if (!this.phoneForm.phone) {
        this.$toast("请输入手机号后，获取验证码");
        return;
      }
      this.changeTimer();

      this.sendCodeAction({ phone: this.phoneForm.phone }).then((res) => {
        let { msg, success } = res;
        this.$toast(msg);
      });
    },
    changeTimer() {
      this.isShow = false; //倒计时
      this.count = 60; //赋值3秒
      var times = setInterval(() => {
        this.count--; //递减
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.isShow = true;
          clearInterval(times);
        }
      }, 1000); //1000毫秒后执行
    },
    toggleType() {
      if (this.loginFormType == 0) {
        this.loginFormType = 1;
        return;
      }
      if (this.loginFormType == 1) {
        this.loginFormType = 0;
        return;
      }
    },
    handleLogin() {
      if (!this.checked) {
        this.$toast("请先阅读并同意《用户服务协议》《隐私权政策》");
        return;
      }
      switch (this.loginFormType) {
        case 0:
          this.accountLogin();
          break;
        case 1:
          this.passLogin();
          break;
      }
    },
    /*
     *@des: 初始化校验
     */
    initAccountValidator() {
      this.formValidator = new Schema(this.loginRules);
    },
    /*
     *@des: 校验表单
     */
    accountValidate() {
      this.initAccountValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.loginForm, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve();
          })
          .catch((e) => {});
      });
    },
    accountLogin() {
      this.accountValidate().then((res) => {
        let clientId, mobileType;
        if (window.plus) {
          clientId = plus.push.getClientInfo().clientid;
          if (plus.os.name == "Android") {
            mobileType = 1;
          }
          if (plus.os.name == "iOS") {
            mobileType = 2;
          }
        }
        const params = {
          ...this.loginForm,
          email:
            this.loginForm.loginType == "3" ? this.loginForm.staffCode : "",
          phone:
            this.loginForm.loginType == "2" ? this.loginForm.staffCode : "",

          clientId,
          mobileType,
        };

        if (localStorage.getItem("Authorization_OA_H5")) {
          localStorage.removeItem("Authorization_OA_H5");
          localStorage.removeItem("staffCode_H5");
          localStorage.removeItem("userName_H5");
          localStorage.removeItem("openId_H5");
        }
        this.loginAction({
          ...params,
          remark: "APP_OA",
        })
          .then((res) => {
            let { data, success } = res;
            if (success) {
              if (data.firstLoginStatus == 1) {
                this.$toast("您是首次登陆需要修改密码");
                this.$router.replace("/changePass");
              } else {
                if (this.pageUrl) {
                  this.$router.replace(this.pageUrl);
                } else {
                  this.$router.replace("/");
                }
              }
            }
          })
          .finally((res) => {
            this.getImg();
          });
      });
    },

    /*
     *@des: 初始化校验
     */
    initPassValidator() {
      this.formValidator = new Schema(this.phoneRules);
    },
    /*
     *@des: 校验表单
     */
    passValidate() {
      this.initPassValidator();
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.phoneForm, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve();
          })
          .catch((e) => {});
      });
    },
    passLogin() {
      this.passValidate().then((res) => {
        let clientId, mobileType;
        if (window.plus) {
          clientId = plus.push.getClientInfo().clientid;
          if (plus.os.name == "Android") {
            mobileType = 1;
          }
          if (plus.os.name == "iOS") {
            mobileType = 2;
          }
        }

        this.phoneLoginAction({
          ...this.phoneForm,
          clientId,
          mobileType,
        }).then((res) => {
          let { data, success } = res;
          if (success) {
            if (data.firstLoginStatus == 1) {
              this.$toast("您是首次登陆需要修改密码");
              this.$router.replace("/changePass");
            } else {
              if (this.pageUrl) {
                this.$router.replace(this.pageUrl);
              } else {
                this.$router.replace("/");
              }
            }
          }
        });
      });
    },
    goForgetPass() {
      this.$router.replace("/fogetPass");
    },
    switchWechat() {
      this.loginFormType = 3;
    },
    switchPhone() {
      this.loginFormType = 1;
    },
    switchPass() {
      this.loginFormType = 0;
    },
    goWechatLogin() {
      this.$router.replace("/h5Login");
    },
    showPass() {
      this.flag = !this.flag;
    },
    goAgreementZC() {
      this.dialogZC = true;
    },
    goAgreementYS() {
      this.dialogYS = true;
    },
    // wxAuth() {
    //   const appid = process.env.VUE_APP_WXAPPID;
    //   const redirect_uri = encodeURIComponent(
    //     `${window.location.origin}${window.location.pathname}`
    //   );
    //   const state = "";
    //   const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
    //   window.location.replace(url);
    // },
    // onOpen() {
    //   let code = this.$route.query.code;
    //   if (!code) {
    //     return;
    //   }
    //   const postData = {
    //     code: this.$route.query.code,
    //   };
    //   this.h5LoginAction(postData).then((res) => {
    //     this.$router.replace("/");
    //   });
    // },

    register(){
      this.$router.push("/register");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
$bg: #fff;
$dark_gray: #889aa4;
$light_gray: #eee;

.form-container {
  width: 7.5rem;
  min-height: 100%;
  background-image: url("~@/assets/images/background.jpg");
  background-size: 100% 100%;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  .top {
    width: 100%;
    text-align: center;
    .logo {
      width: 1.56rem;
      height: 1.56rem;
      margin-bottom: 0.3rem;
    }
    .title {
      height: 0.59rem;
      font-size: 0.42rem;
      font-weight: 600;
      color: #000000;
      line-height: 0.59rem;
      margin-bottom: 1.46rem;
    }
  }
  .box {
    width: 6.6rem;
    background: #ffffff;
    border-radius: 0.16rem;
    opacity: 0.79;
    border: 1px solid transparent;
    margin-left: 0.45rem;
    .password-content {
      .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.3rem;
        .pass-box {
          position: relative;
          .eye-box {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            width: 0.8rem;
            height: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .eye {
              width: 0.21rem;
              height: 0.16rem;
            }
          }
        }
        .login-input {
          width: 6rem;
          height: 0.8rem;
          background: #ededed;
          border-radius: 0.08rem;
          margin-bottom: 0.3rem;
          padding: 0 0.2rem;
        }
        .login-input-pass {
          width: 6rem;
          height: 0.8rem;
          background: #ededed;
          border-radius: 0.08rem;
          margin-bottom: 0.3rem;
          padding: 0 1rem 0 0.2rem;
        }
        .check-code {
          display: flex;
          width: 6rem;
          margin-bottom: 0.3rem;
          .code-input {
            width: 4rem;
            height: 0.8rem;
            background: #ededed;
            border-radius: 0.08rem;
            margin-bottom: 0.3rem;
            padding: 0 0.2rem;
          }
          .verication-img {
            width: 2rem;
            height: 0.8rem;
            background: #ffffff;
            border-radius: 0.08rem;
          }
        }
        .check-msg {
          display: flex;
          width: 6rem;
          margin-bottom: 0.3rem;
          .msg-input {
            width: 4rem;
            height: 0.8rem;
            background: #ededed;
            border-radius: 0.08rem;
            margin-bottom: 0.3rem;
            padding: 0 0.2rem;
          }
          .count {
            width: 1.78rem;
            height: 0.8rem;
            background: #0094ee;
            border-radius: 0.08rem;
            color: #fff;
            font-size: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0.2rem;
          }
        }
      }

      .active {
        height: 100%;
        transition: height 2s;
      }
      .go-forget-pass {
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
      }
    }

    .wechat-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 1.4rem;
      .wechat-logo {
        width: 0.46rem;
        height: 0.46rem;
        margin-right: 0.1rem;
      }
      .split {
        border-bottom: 1px solid #999;
        width: 3.8rem;
        padding-top: 0.12rem;
        height: 0.26rem;
        margin-bottom: 0.57rem;
        margin-top: 0.72rem;
        text-align: center;
        .split-text {
          background: #fff;
          font-size: 0.26rem;
          display: inline-block;
          padding: 0 0.1rem;
        }
      }

      .other-login {
        display: flex;
        justify-content: center;
        margin-bottom: 2.2rem;
        .item {
          width: 2.6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          .other-logo {
            width: 1.06rem;
            height: 1.06rem;
          }
          .text {
            font-size: 0.28rem;
            margin-top: 0.2rem;
          }
        }
      }

      .active {
        height: 100%;
        transition: height 2s;
      }
    }

    .login-btn {
      width: 6rem;
      height: 0.8rem;
      margin-bottom: 0.3rem;
      font-size: 0.3rem;
      color: #ffffff;
      background: #0094ee;
      border-radius: 0.08rem;
      border: none;
      .wechat-button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .switch-name {
      width: 100%;
      text-align: center;
      font-size: 0.28rem;
      color: #666666;
      margin: 0.4rem 0;
      .switch-text {
        cursor: pointer;
        display: inline-block;
      }
    }

    .wechat-login {
      width: 100%;
      text-align: center;
      height: 0.28rem;
      color: #666666;
      margin: 0.4rem 0;
      .switch-text {
        cursor: pointer;
        display: inline-block;
      }
    }
    .agreement {
      padding: 0.3rem;
      font-size: 0.22rem;
      display: flex;
      align-items: center;
      .link {
        color: #1989fa;
      }
    }
  }
  .tips {
    color: #666666;
    display: flex;
    padding: 0.3rem;
    p{
      font-size: 0.28rem;
      margin: 0 0 0.1rem 0;
    }
  }
}
</style>
